import React from "react";

function ScoreCard({ score }) {
  return (
    <div className="w-100 bg-white rounded-3 p-3 mt-2 text-center">
      <span className="fw-bold fs-6">Score</span>
      <p className="fw-bold fs-1 m-0">{score}</p>
    </div>
  );
}

export default ScoreCard;

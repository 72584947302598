import axios from 'axios'
import { Base_url } from '../utils/constants';

axios.defaults.baseURL = Base_url;

const api = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        "token": "miyqggucnmhijwlbweoanucplvxmezcq"
    },
})

api.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401 || error.response.status === 500) {
            setTimeout(() => {
                window.location.href = '*';
            }, 1500)
        }
        return Promise.reject(error);
    });

api.interceptors.response.use(
    response => response,
    error => {
        console.error('Error:', error);
        if (error.response.status === 401 || error.response.status === 500) {
            setTimeout(() => {
                window.location.href = '*';
            }, 1500)
        }
        return Promise.reject(error);
    }
);

export default api


import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import SpinWheel from "./SpinWheel";
import { getQuestions } from "../service/games";
import { Base_url } from "../utils/constants";
import axios from "axios";
import ThankyouPage from "../components/Thankyou";
import { useParams, useSearchParams } from "react-router-dom";
import { sessionDataContext } from "../App";
import UserLeaderBoard from "./Components/UserLeaderBoard";

const Dashboard = () => {
    const data = [
        { option: '0' },
        { option: '1' },
        { option: '2' },
        { option: '3' },
        { option: '4' },
        { option: '5' },
        { option: '6' },
        { option: '7' },
        { option: '8' },
        { option: '9' },
        { option: '10' },
        { option: '11' },
        { option: '12' },
        { option: '13' },
        { option: '14' },
        { option: '15' },
        { option: '16' },
        { option: '17' },
        { option: '18' },
        { option: '19' },
        { option: '20' },
        { option: '21' },
        { option: '22' },
    ];

    const [questions, setQuestions] = useState([]);
    const [mustSpin, setMustSpin] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    console.log(isLoaded, 'pppop');

    const [prizeNumber, setPrizeNumber] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [leaderBoardData, setLeaderBoardData] = useState()

    const handleClose = () => setShowModal(false); // Close modal
    const handleShow = () => setShowModal(true); // Open modal
    const slices = data.length;
    const pointerOffset = 3;
    const adjustedPrizeNumber = (prizeNumber - pointerOffset + slices) % slices;
    const [searchParams] = useSearchParams();
    const customerId = searchParams.get("customer_id"); // Extract 'customer_id'
    const [sessionData, setSessionData] = useContext(sessionDataContext)

    useEffect(() => {
        GetLeaderBoard();
    }, []);

    const GetLeaderBoard = async () => {
        try {
            const id = customerId ? btoa(customerId) : btoa('753982145')
            const res = await axios.get(`${Base_url}api/leaderboard/get`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    "token": "miyqggucnmhijwlbweoanucplvxmezcq",
                    "user_token": id
                }
            })
            setLeaderBoardData(res?.data?.data);
        }
        catch (e) {
            window.location.href = '*'
        }
    }

    const handleSpinClick = async () => {
        const newPrizeNumber = Math.floor(Math.random() * slices);
        const adjustedPrizeNumber = (newPrizeNumber - pointerOffset + slices) % slices;

        setPrizeNumber(newPrizeNumber);
        setMustSpin(true);


        const data = {
            session_id: sessionData?.id,
            question_id: questions[0]?.id,
            type: "spin_wheel",
            answer: adjustedPrizeNumber,
            time_spent: 0
        };

        try {
            const id = customerId ? btoa(customerId) : btoa('753982145')
            const res = await axios.post(`${Base_url}api/question-answer/submit-answer`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    "token": "miyqggucnmhijwlbweoanucplvxmezcq",
                    "user_token": id
                }
            });
            setTimeout(() => {
                setIsLoaded(true)
            }, 6500)
        } catch (e) {
            window.location.href = '*'
        }
    };

    useEffect(() => {
        GetQuestion();
    }, []);

    const GetQuestion = async () => {
        const params = {
            module_type: "spin_wheel",
        };
        let res = await getQuestions(params);
        setQuestions(res?.data?.data);
    };

    return (
        <>
            {isLoaded ? (
                <div className="d-flex justify-content-center align-items-center">
                    <div className="thank-you d-flex justify-content-center align-items-center flex-column">
                        <ThankyouPage text={"Thank you for submitting your answer. Get ready for the next round if you qualify!"} />
                    </div>
                </div>
            ) : (
                <div className="spin-wheel container mt-5 mb-5">
                    <div className="heading mb-5 d-flex flex-column justify-content-center align-items-center w-100">
                        <h1>{questions?.length > 0 ? questions[0]?.description : "Loading..."}</h1>
                    </div>
                    <div className="d-flex w-100 btn-leader-board mb-3">
                        <div className="ms-auto">
                            <button
                                className="rounded-3 fw-bold border-0 py-2 px-3 mt-1 bg-warning text-black"
                                onClick={handleShow}
                            >
                                See Leader Board
                            </button>
                        </div>
                    </div>
                    <Row className="row-spinner p-5 display-flex align-items-center">
                        <Col md={12} lg={6} className="content-column">
                            <div className="content">
                                <h1 className="mb-4">Spin The Wheel</h1>
                                <p className="mb-4">Welcome to our exclusive Spin to Win game! Simply click on the wheel to spin and stand a chance to win amazing discounts, free products, and other fantastic prizes. Each spin gives you an opportunity to score big and make your shopping experience even more rewarding.</p>
                                <h3 className="mb-2">Prizes Include:</h3>
                                <ul className="mb-5">
                                    <li>Exclusive discounts up to 50%</li>
                                    <li>Free shipping on your next order</li>
                                    <li>Complimentary products</li>
                                    <li>And many more surprises!</li>
                                </ul>
                                <Button onClick={handleSpinClick} disabled={mustSpin}>Spin Now</Button>
                            </div>
                        </Col>
                        <Col md={{ span: 12, offset: 0 }} lg={{ span: 5, offset: 1 }} className="spinner-column">
                            <SpinWheel
                                mustSpin={mustSpin}
                                setMustSpin={setMustSpin}
                                handleSpinClick={handleSpinClick}
                                prizeNumber={prizeNumber}
                                data={data}
                            // onComplete={onSpinComplete} // Pass spin complete handler
                            />
                        </Col>
                    </Row>
                </div>
            )}
            {/* Leaderboard Modal */}
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h2> Top {leaderBoardData?.length} {leaderBoardData?.length > 1 ? "players" : "player"} who qualified for next round </h2>
                    <p>
                        <b> Name </b>
                    </p>
                    <div className="leader-board pe-3">
                        <UserLeaderBoard />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Dashboard;

import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Accuracy from "./Components/Accuracy";
import ActualPriceCard from "./Components/ActualPriceCard";
import GuessInput from "./Components/GuessInput";
import ImageCard from "./Components/ImageCard";
import NextBtn from "./Components/NextBtn";
import ScoreCard from "./Components/ScoreCard";
import Stepper from "./Components/Stepper";
import TimeLeftCard from "./Components/TimeLeftCard";
import UserLeaderBoard from "./Components/UserLeaderBoard";
import { getQuestions } from "../service/games";
import { postAnswer } from "../service/postAnswer";
import axios from "axios";
import { Base_url } from "../utils/constants";
import ThankyouPage from "../components/Thankyou";
import { useParams, useSearchParams } from "react-router-dom";
import { sessionDataContext } from "../App";

const GuessGame = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [timeLeft, setTimeLeft] = useState();
  const [guessedPrice, setGuessedPrice] = useState(null);
  const [isGuessSubmitted, setIsGuessSubmitted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isEndGame, setIsEndGame] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("customer_id"); // Extract 'customer_id'
  const [sessionData, setSessionData] = useContext(sessionDataContext)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [score, setScore] = useState(0);

  useEffect(() => {
    GetQuestion();
  }, []);

  const GetQuestion = async () => {
    const params = {
      module_type: "price_guessing",
    };
    let res = await getQuestions(params);
    setQuestions(res?.data?.data);
    setTimeLeft(res?.data?.data[0]?.time_limit);
  };

  const handleClose = () => setShowModal(false); // Close modal
  const handleShow = () => setShowModal(true); // Open modal

  useEffect(() => {
    if (timeLeft > 0 && !isGuessSubmitted) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else if (timeLeft === 0 && !isGuessSubmitted) {
      handleNext(); // Move to next question when time runs out
    }
  }, [timeLeft, isGuessSubmitted]);

  useEffect(() => {
    if (timeLeft === 0 && !isGuessSubmitted) {
      submitAnswerIfnotSelected()
    }
  }
    , [timeLeft])

  const submitAnswerIfnotSelected = async () => {
    const data = {
      session_id: sessionData?.id,
      question_id: questions[currentQuestion]?.id,
      type: "price_guessing",
      answer: 0,
      time_spent: timeLeft
    }
    try {
      const id = customerId ? btoa(customerId) : btoa('753982145')
      const res = await axios.post(`${Base_url}api/question-answer/submit-answer`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "token": "miyqggucnmhijwlbweoanucplvxmezcq",
          "user_token": id
        }
      })
      setScore((prev) => parseFloat((prev + res?.data?.data?.score).toFixed(2)));
    }
    catch (e) {
      window.location.href = '*'
    }
  }
  const handleGuessSubmit = async (value) => {
    setGuessedPrice(value);
    setIsGuessSubmitted(true);
    const data = {
      session_id: sessionData?.id,
      question_id: questions[currentQuestion]?.id,
      type: "price_guessing",
      answer: value,
      time_spent: timeLeft
    }
    try {
      const id = customerId ? btoa(customerId) : btoa('753982145')
      const res = await axios.post(`${Base_url}api/question-answer/submit-answer`, data, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          "token": "miyqggucnmhijwlbweoanucplvxmezcq",
          "user_token": id
        }
      })
      setScore((prev) => parseFloat((prev + res?.data?.data?.score).toFixed(2)));
    }
    catch (e) {
      window.location.href = '*'
    }
  };

  const handleNext = () => {
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion((prev) => prev + 1);
      setTimeLeft(questions[currentQuestion + 1]?.time_limit);
      setGuessedPrice(null);
      setIsGuessSubmitted(false);
      setActiveStep((prev) => prev + 1);
    } else {
      setIsEndGame(true);
    }
  };

  return (
    <React.Fragment>

      {!isEndGame ? <div className="d-flex justify-content-center container mt-5 flex-column align-items-center">
        <div className="w-75 fs-2 fw-semibold heading">
          {questions[currentQuestion]?.description}
        </div>

        {/* Stepper and Leader board button */}
        <div className="w-100 d-flex justify-content-between">
          <div className="mx-auto">
            <Stepper activeStep={activeStep} totalSteps={questions.length} />
          </div>
        </div>

        {/* Game */}
        <Row className="w-100">
          <Col md={8}>
            {!isGuessSubmitted ? (
              <ImageCard
                imageUrl={questions[currentQuestion]?.image_url}
                question={questions[currentQuestion]?.question}
              />
            ) : (
              <ActualPriceCard
                actualPrice={questions[currentQuestion]?.actual_price}
                guessedPrice={guessedPrice}
                question={questions[currentQuestion]?.question}
              />
            )}
          </Col>
          <Col md={4}>
            <TimeLeftCard timeLeft={timeLeft} />
            <Accuracy
              actualPrice={questions[currentQuestion]?.actual_price}
              guessedPrice={guessedPrice}
            />
            <ScoreCard score={score} />
          </Col>
        </Row>
        <Row className="w-100 align-items-center">
          <Col md={8}>
            <GuessInput
              guessed={isGuessSubmitted}
              onGuessSubmit={handleGuessSubmit}
            />
          </Col>
          <Col md={4}>
            {!isEndGame && <NextBtn isGuessSubmitted={isGuessSubmitted} onNext={handleNext} isEndGame={isEndGame} />}
          </Col>
        </Row>
      </div> : <div className="d-flex justify-content-center align-items-center">
        <div className="thank-you d-flex justify-content-center align-items-center flex-column">
          <ThankyouPage text={"Thank you for submitting your answer. Get ready for the next round if you qualify!"} />
        </div>
      </div>}
    </React.Fragment>
  );
};

export default GuessGame;
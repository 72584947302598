import React from "react";

function NextBtn({ onNext, isEndGame, isGuessSubmitted }) {
  return (
    <button
      className={`w-100  rounded-3 fw-bold border-0 py-3 px-5 mt-1 next-btn text-white`}
      onClick={onNext}
      disabled={!isGuessSubmitted}
    >
      {isEndGame ? "Completed" : "NEXT"}
    </button>
  );
}

export default NextBtn;

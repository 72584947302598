import axios from 'axios';

axios.defaults.baseURL = "https://api.playluckyduck.trangotechdevs.com/";

const api = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SjFjMlZ5WDJsa0lqb3lMQ0pwWVhRaU9qRTNNamd6TXpVM09UTXNJbVY0Y0NJNk1UY3lPRGMyTnpjNU15d2lhWE56SWpvaWJXbDVjV2RuZFdOdWJXaHBhbmRzWW5kbGIyRnVkV053YkhaNGJXVjZZM0VpTENKemRXSWlPaUp0YVhseFoyZDFZMjV0YUdscWQyeGlkMlZ2WVc1MVkzQnNkbmh0WlhwamNTSXNJbXAwYVNJNkluVnpaWEl0TWlKOS5UNEpKblExWWhkbUlhdlktNXVKMks5VnVsRk5OTXB0RHpuanUzRF9PaGxR',
        "token": "miyqggucnmhijwlbweoanucplvxmezcq"
    },
});

api.interceptors.response.use(
    response => response,
    error => {
        if (error.response && (error.response.status === 401 || error.response.status === 500)) {
            setTimeout(() => {
                window.location.href = '*'; // Redirect to an error or login page
            }, 1500);
        }
        return Promise.reject(error);
    }
);

export default api;

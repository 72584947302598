import React from "react";

function ActualPriceCard({ actualPrice, guessedPrice, question }) {
  return (
    <div className="w-100 bg-white rounded-3 p-3">
      <div className="d-flex flex-row justify-content-between">
        <div className="fw-medium fs-5">Your Guess</div>
        <div className="fw-bold fs-5">{guessedPrice}</div>
      </div>

      <hr></hr>

      <div className="d-flex flex-column justify-content-between py-5">
        <div className="fw-bold fs-5">Actual Price</div>
        <div className="fw-bold rate">$ {actualPrice}</div>
      </div>

      <hr></hr>

      <h5 className="m-0 d-flex">{question}</h5>
    </div>
  );
}

export default ActualPriceCard;

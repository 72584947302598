import React, { act, useContext, useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Stepper from "../components/stepper";
import { getQuestions } from "../service/games";
import axios from "axios";
import UserLeaderBoard from "./Components/UserLeaderBoard";
import { Base_url } from "../utils/constants";
import ThankyouPage from "../components/Thankyou";
import { useParams, useSearchParams } from "react-router-dom";
import { sessionDataContext } from "../App";
import ThemeSpinner from "../components/ThemeSpinner";


const Mcqs = () => {
    const [questions, setQuestions] = useState(null);
    const [active, setActive] = useState(null);
    const [timeLeft, setTimeLeft] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitLoading, setIsSubmitLoading] = useState(false)
    const [endGame, setEndGame] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selected, setSelected] = useState(false);
    const handleClose = () => setShowModal(false); // Close modal
    const handleShow = () => setShowModal(true); // Open modal
    const [leaderBoardData, setLeaderBoardData] = useState()
    const [searchParams] = useSearchParams();
    const customerId = searchParams.get("customer_id"); // Extract 'customer_id'
    const [sessionData, setSessionData] = useContext(sessionDataContext)
    const [score, setScore] = useState(0);

    useEffect(() => {
        GetQuestion();
        GetLeaderBoard();
    }, []);

    const GetLeaderBoard = async () => {
        try {
            const id = customerId ? btoa(customerId) : btoa('753982145')
            const res = await axios.get(`${Base_url}api/leaderboard/get`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    "token": "miyqggucnmhijwlbweoanucplvxmezcq",
                    "user_token": id
                }
            })
            setLeaderBoardData(res?.data?.data);
        }
        catch (e) {
            window.location.href = '*'
        }
    }
    const GetQuestion = async () => {
        setIsLoading(true);
        try {
            const params = {
                module_type: "mcqs",
            };
            let res = await getQuestions(params);
            setQuestions(res?.data?.data);
            setTimeLeft(res?.data?.data[0]?.time_limit);
        }
        catch (error) {
            console.log("error detected");
        }
        finally {
            setIsLoading(false)
        }
    };

    const handleOptionClick = (choiceId) => {
        setActive(choiceId);
    };


    useEffect(() => {
        if (timeLeft === 0) {
            submitAnswerIfnotSelected()
        }
    }, [timeLeft])

    const submitAnswerIfnotSelected = async () => {
        if (questions) {
            const data = {
                session_id: sessionData?.id,
                question_id: questions[currentQuestion]?.id,
                type: "mcqs",
                answer: 0,
                time_spent: timeLeft
            }
            try {
                setIsSubmitLoading(true)
                const id = customerId ? btoa(customerId) : btoa('753982145')
                const res = await axios.post(`${Base_url}api/question-answer/submit-answer`, data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        "token": "miyqggucnmhijwlbweoanucplvxmezcq",
                        "user_token": id
                    }
                })
                setScore((prev) => parseFloat((prev + res?.data?.data?.score).toFixed(2)));
                setIsSubmitLoading(false)
            }
            catch (e) {
                setIsSubmitLoading(false)
                window.location.href = '*'
            }
        }
    }

    const handleNext = async () => {
        if (questions) {
            if (active?.option) {
                const data = {
                    session_id: sessionData?.id,
                    question_id: questions[currentQuestion]?.id,
                    type: "mcqs",
                    answer: active?.option,
                    time_spent: timeLeft
                }
                try {
                    setIsSubmitLoading(true)
                    const id = customerId ? btoa(customerId) : btoa('753982145')
                    const res = await axios.post(`${Base_url}api/question-answer/submit-answer`, data, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            "token": "miyqggucnmhijwlbweoanucplvxmezcq",
                            "user_token": id
                        }
                    })
                    setScore((prev) => parseFloat((prev + res?.data?.data?.score).toFixed(2)));
                    setIsSubmitLoading(false)
                }
                catch (e) {
                    setIsSubmitLoading(false)
                    window.location.href = '*'
                }
            }
            if (currentQuestion < questions?.length - 1) {
                setCurrentQuestion(currentQuestion + 1);
                setTimeLeft(questions[currentQuestion + 1]?.time_limit);
            } else {
                setEndGame(true);
            }
            setActive(null);
        }
    };

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);

            return () => clearInterval(timer);
        } else if (timeLeft === 0) {
            handleNext(); // Move to next question when time runs out
        }
    }, [timeLeft]);

    return (
        <>

            {
                !endGame ? !isLoading ? <div className="spin-wheel container mt-5 mb-5 mcqs">
                    <div className="heading mb-5 d-flex flex-column justify-content-center align-items-center w-100">
                        <h1>{questions?.length > 0 ? questions[0]?.description : "Loading..."}</h1>

                        {/* Stepper and Leader board button */}
                        <div className="w-100 d-flex justify-content-between">
                            <div className="mx-auto">
                                <Stepper activeStep={currentQuestion} totalSteps={questions?.length} />
                            </div>
                        </div>

                        <div className="d-flex w-100 btn-leader-board mb-3">
                            <div className="ms-auto">
                                <button
                                    className="rounded-3 fw-bold border-0 py-2 px-3 mt-1 bg-warning text-black"
                                    onClick={handleShow}
                                >
                                    See Leader Board
                                </button>
                            </div>
                        </div>

                        <div className="quiz-component">
                            <Row>
                                <Col md={12} lg={8} className="question-sec">
                                    {questions?.length > 0 && (
                                        <div key={questions[currentQuestion]?.id} className="quiz w-100">
                                            <h3>{questions[currentQuestion]?.question}</h3>
                                            <div className="options mt-5 d-flex justify-content-center align-items-center">
                                                {questions[currentQuestion]?.question_options?.map((c) => (
                                                    <p
                                                        className={`cursor ${active?.id === c?.id && 'active'}`}
                                                        onClick={() => handleOptionClick(c)}
                                                        key={c?.id}
                                                    >
                                                        {c?.option}
                                                    </p>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </Col>
                                <Col md={12} lg={4}>
                                    <div className="duration-details d-flex flex-column justify-content-center align-items-center">
                                        <div className="time d-flex flex-column justify-content-center align-items-center">
                                            <p>Time</p>
                                            <div className={`time-left  ${timeLeft === 0 ? "bg-danger" : "bg-black"} `}>
                                                Time Left <span className="ms-2">: {timeLeft}s</span>
                                            </div>
                                        </div>
                                        <div className="score d-flex flex-column justify-content-center align-items-center">
                                            <p>Score</p>
                                            <div className="score-count">
                                                {score}
                                            </div>
                                        </div>
                                        <Button disabled={timeLeft < 1 || !active?.option ? true : false} className="btn w-100" onClick={handleNext}>
                                            {isSubmitLoading ? <ThemeSpinner /> : "Next"}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div> :
                    <div className="d-flex justify-content-center align-items-center">Loading...</div> :
                    <div className="thank-you d-flex justify-content-center align-items-center flex-column">
                        <ThankyouPage text={"Thank you for submitting your answer. Get ready for the next round if you qualify!"} />
                    </div>
            }
            {/* Leaderboard Modal */}
            <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <h2> Top {leaderBoardData?.length} {leaderBoardData?.length > 1 ? "players" : "player"} who guessed closest to the correct price </h2>
                    <p>
                        <b> Name </b>
                    </p>
                    <div className="leader-board pe-3">
                        <UserLeaderBoard />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Mcqs;

import React from "react";

function ImageCard({ imageUrl, question }) {
  return (
    <div className="w-100 image-guess-game bg-white rounded-3 p-3">
      <img style={{ height: "275px" }} alt="" src={imageUrl} width={"100%"} className="rounded-3 object-fit-contain image-fluid" />
      <hr></hr>
      <h5 className="m-0 d-flex">{question}</h5>
    </div>
  );
}

export default ImageCard;

import React from "react";

const Stepper = ({ activeStep, totalSteps }) => {
    const steps = Array.from({ length: totalSteps }, (_, index) => index);

    return (
        <div className="stepper-container">
            {steps.map((step, index) => (
                <div key={index} className="step-container">
                    <div
                        className={`step ${step < activeStep
                            ? "completed"
                            : step === activeStep
                                ? "current"
                                : "inactive"
                            }`}
                    >
                        <div className="inner-dot"></div>
                    </div>
                    {index < totalSteps - 1 && (
                        <div
                            className={`step-line ${step < activeStep ? "completed-line" : "inactive-line"
                                }`}
                        ></div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Stepper;

import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import { Base_url } from '../utils/constants';
import { sessionDataContext } from '../App';
import Door from '../components/Door';
import ThankyouPage from '../components/Thankyou';
import ConfettiExplosion from 'react-confetti-explosion';

function DoorComponent() {
    const [data, setData] = useState([]);
    const [selectedData, setSelectedData] = useState(null)
    const [showPage, setShowPage] = useState(false);
    const [searchParams] = useSearchParams();
    const customerId = searchParams.get("customer_id");
    const [sessionData] = useContext(sessionDataContext);
    console.log(selectedData);

    useEffect(() => {
        GetPromoCodes();
    }, []);

    const GetPromoCodes = async () => {
        try {
            const id = customerId ? btoa(customerId) : btoa('753982145');
            const res = await axios.get(`${Base_url}api/session-promo-code/get?session_id=${sessionData?.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    "token": "miyqggucnmhijwlbweoanucplvxmezcq",
                    "user_token": id,
                },
            });

            const promoCodes = res?.data?.data || [];
            const limitedPromoCodes = promoCodes.slice(0, 2); // Limit to two promo codes

            const tryAgain = {
                promo_code_id: "TRY AGAIN",
                is_assigned: false,
                promo_code: {
                    discount_type: null,
                    discount_value: 0,
                    description: "Better luck next time!",
                    status: "inactive",
                },
            };

            const doorsData = [...limitedPromoCodes, tryAgain];
            setData(shuffleDoors(doorsData));
        } catch (error) {
            alert("An Error Occurred!");
        }
    };

    const shuffleDoors = (doors) => {
        return doors.sort(() => Math.random() - 0.5);
    };

    return (
        <>
            {
                !showPage ?
                    <div className="door-page mt-5">
                        < div className="d-flex justify-content-center align-items-center final-round" >
                            Final Round
                        </div >
                        <div className="heading mt-3 mb-5 d-flex flex-column justify-content-center align-items-center w-100">
                            <h1>Guess the total retail price of the items behind your chosen door.</h1>
                            <p className="sub-content">
                                Guess the total retail price of the items behind your chosen door for a chance to win all the prizes hidden there!
                            </p>
                        </div>
                        <Door data={data} setShowPage={setShowPage} showPage={showPage} setSelectedData={setSelectedData} />
                    </div >
                    : <div className="thank-you d-flex justify-content-center align-items-center flex-column">
                        <ThankyouPage text={`Thank you for participating in this game! ${selectedData?.promo_code_id === "TRY AGAIN" ? "Good luck next time!" : ''}`} timeShow={false} prize={selectedData?.promo_code_id === "TRY AGAIN" ? false : true} prizeAmount={`${selectedData?.promo_code?.discount_type === "percentage" ? `${selectedData?.promo_code?.discount_value}%` : `$${selectedData?.promo_code?.discount_value}`}`} />
                        <ConfettiExplosion />
                    </div>
            }
        </>
    );
}

export default DoorComponent;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { SiTicktick } from "react-icons/si";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { Base_url } from "../utils/constants";
import { FaGift } from "react-icons/fa";

const ThankyouPage = ({ text, timeShow = true, prize, prizeAmount }) => {
    const [searchParams] = useSearchParams();
    const customerId = searchParams.get("customer_id"); // Extract 'customer_id'
    const [data, setData] = useState(null);

    useEffect(() => {
        fetchSession();
    }, []);

    const fetchSession = async () => {
        try {
            const id = customerId ? btoa(customerId) : btoa("753982145");
            const res = await axios.get(`${Base_url}api/session/get-status`, {
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    token: "miyqggucnmhijwlbweoanucplvxmezcq",
                    user_token: id,
                },
            });
            setData(res?.data?.data);
        } catch (error) {
            console.error("Failed to fetch session:", error);
        }
    };

    const formatTime = (dateString) => {
        const date = new Date(dateString);
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        return `${hours}:${minutes}`;
    };

    const getStartTime = () => {
        switch (data?.activeRound) {
            case 1:
                return formatTime(data?.round_two_start_at) || "-";
            case 2:
                return formatTime(data?.round_three_start_at) || "-";
            case 3:
                return formatTime(data?.round_four_start_at) || "-";
            default:
                return "-";
        }
    };

    return (
        <>
            <div className="d-flex justify-content-center align-items-center mb-3">
                <SiTicktick color="green" size={100} />
            </div>
            <p>{text}</p>
            {timeShow &&
                <div className="next-round d-flex mt-4">
                    Next Round will Starts At:
                    <span className="ms-3">{data && getStartTime()}</span>
                </div>
            }
            {prize &&
                <>
                    <div className="next-round prize-amount d-flex mt-4">
                        {prizeAmount}
                    </div>
                    <div className="prize-email">
                        The promo code has been emailed you
                    </div>
                </>

            }
            {prize && <div className='link-door d-flex flex-column justify-content-center align-items-center shop-now'>
                <FaGift color="green" size={80} className='mb-3' />
                <a href={"https://playluckyduck.com"} target="_top">
                    Shop Now
                </a>
            </div>
            }
        </>
    );
};

export default ThankyouPage;

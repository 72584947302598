import React from "react"
import { LuGamepad2 } from "react-icons/lu";

const NextRound = ({ data, time }) => {
    const formatTime = (dateString) => {
        const date = new Date(dateString);
        const hours = date.getHours().toString().padStart(2, "0"); // Local time
        const minutes = date.getMinutes().toString().padStart(2, "0"); // Local time
        return `${hours}:${minutes}`;
    };
    return (
        <>
            <div className="thank-you d-flex justify-content-center align-items-center flex-column">

                <div className="d-flex justify-content-center align-items-center mb-3">
                    <LuGamepad2 color="green" size={100} />
                </div>
                <div className="next-round d-flex mt-4 align-items-center">
                    Next Round will Starts At:
                    <span className="ms-3" style={{
                        padding: 0,
                        margin: 0,
                    }}>{data && formatTime(time)}</span>
                </div>
            </div>
        </>
    )
}

export default NextRound
import React from "react";
import { MdError } from "react-icons/md";

const ErrorPage = ({ text }) => {
    return (
        <div className="thank-you d-flex justify-content-center align-items-center flex-column">
            <div className="d-flex justify-content-center align-items-center mb-3">
                <MdError color="red" size={100} />
            </div>
            <p className="mb-3">{text}</p>
        </div>
    )
}
export default ErrorPage
import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import GuessGame from "../pages/GuessGame";
import Mcqs from "../pages/Mcqs";
import ErrorPage from "../pages/ErrorPage";
import axios from "axios";
import { Base_url } from "../utils/constants";
import DoorCOmponent from "../pages/Door";
import { sessionDataContext } from "../App";
import NextRound from "../components/NextRound";

const Navigations = () => {
  const [data, setData] = useState(null); // Initialize as null or undefined
  const [activeId, setActiveId] = useState(null); // Initialize as null or undefined
  const [loading, setLoading] = useState(true); // Optional: Loading state
  const [searchParams] = useSearchParams();
  const customerId = searchParams.get("customer_id"); // Extract 'customer_id'
  const [sessionData, setSessionData] = useContext(sessionDataContext)
  const [isLoading, setIsLoading] = useState()
  useEffect(() => {
    let isMounted = true;
    const fetchSession = async () => {
      try {
        const id = customerId ? btoa(customerId) : btoa('753982145')
        const res = await axios.get(`${Base_url}api/session/get-status`, {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            "token": "miyqggucnmhijwlbweoanucplvxmezcq",
            "user_token": id
          }
        })
        setSessionData(res?.data?.data);

        if (isMounted) {
          if (res?.status === 200) {
            setActiveId(res?.data?.data?.activeRound);
            setData(res?.data?.data)
          }
          if (res?.status === 404 || res?.status === 401 || res?.status === 500) {
            window.location.href = '*'
          }
        }
      } catch (error) {
        console.error("Failed to fetch session:", error);
      } finally {
        setLoading(false); // set loading to false after the call finishes
      }
    };

    fetchSession();

    return () => {
      isMounted = false; // cleanup on component unmount
    };
  }, []);

  if (loading) {
    return <div className="d-flex justify-content-center align-items-center">Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/" element={activeId == 1 && data?.playStatus === false && !loading
        ? <GuessGame /> : activeId == 2 && data?.playStatus === false && data?.previousRoundPlayed === true && !loading
          ? <Mcqs /> : activeId == 3 && data?.playStatus === false && data?.previousRoundPlayed === true && !loading
            ? <Dashboard /> : activeId == 4 && data?.playStatus === false && data?.previousRoundPlayed === true && !loading
              ? <DoorCOmponent /> : data?.nextRoundStartTime ?
                <NextRound data={data} time={data?.nextRoundStartTime} /> :
                activeId == 0 && data?.previousRoundPlayed === false || data?.previousRoundPlayed === false ? <ErrorPage text={"You didn't played Previous game"} /> :
                  <ErrorPage text={"There is no active game to play"} />} />
      <Route path="*" element={<ErrorPage text={"There is no active game to play"} />} />

    </Routes>
  );
};

export default Navigations;

import React, { useContext, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Button, Col, Modal, Row } from "react-bootstrap";
import { sessionDataContext } from '../App';
import axios from 'axios';
import { Base_url } from '../utils/constants';
import openDoor from "../assets/images/open.png"
import { FaGift } from "react-icons/fa";
import ConfettiExplosion from 'react-confetti-explosion';

const Door = ({ data, showPage, setShowPage, setSelectedData }) => {
    const [flippedDoorId, setFlippedDoorId] = useState(null); // Track the flipped door ID
    const [isLoaded, setIsLoaded] = useState(false); // Prevent multiple clicks after API call
    const [isLocked, setIsLocked] = useState(false); // Lock mechanism after "TRY AGAIN"
    const [sessionData] = useContext(sessionDataContext);
    const [searchParams] = useSearchParams();
    const [show, setShow] = useState(false);
    const customerId = searchParams.get("customer_id");

    const handleDoorClick = async (door) => {
        if (isLocked || isLoaded) return; // Prevent clicks if locked or already loaded
        setSelectedData(door);

        setShow(true)
        setFlippedDoorId(door.promo_code_id); // Flip the clicked door

        const payload = {
            promo_code_id: door.promo_code_id === "TRY AGAIN" ? null : door.promo_code_id,
            session_id: sessionData?.id,
        };

        try {
            const id = customerId ? btoa(customerId) : btoa('753982145');
            await axios.post(`${Base_url}api/user-promo-code/assign-to-user`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    "token": "miyqggucnmhijwlbweoanucplvxmezcq",
                    "user_token": id,
                },
            });
            setIsLoaded(true); // Prevent further clicks after a valid promo is assigned
            setTimeout(() => {
                setShowPage(true)
            }, 2000)
        } catch (e) {
            console.error("Error assigning promo code:", e);
            alert("An error occurred while assigning the promo code.");
        }
    };


    return (
        <>
            <div className="mt-5 group d-flex justify-content-center align-items-center">
                {data?.filter(dt => !dt?.is_assigned)?.map((door, index) => (
                    <div
                        key={door.promo_code_id}
                        className={`doorFrame margin-css-${index}`}
                        onClick={() => handleDoorClick(door)}
                    >
                        <div className="promo-code">
                            {show ? door?.promo_code_id === "TRY AGAIN" ? (
                                <p>TRY AGAIN - Better luck next time!</p>
                            ) : (
                                <>
                                    <span>Congrats You have won</span>
                                    <p>{door?.promo_code?.discount_type === "percentage" ? `${door?.promo_code?.discount_value}%` : `$${door?.promo_code?.discount_value}`} Discount Coupon</p>
                                    <span>Check your email to find Discount Coupon.</span>
                                    <ConfettiExplosion />
                                </>
                            ) : ''}
                        </div>

                        <div className={`door swing ${flippedDoorId === door?.promo_code_id ? 'flipped' : ``}`}>
                            <figure className="front">
                                <img
                                    src={openDoor}
                                    alt="Front"
                                />
                            </figure>
                            <figure className="back">
                                <img
                                    src={openDoor}
                                    alt="Back"
                                />
                            </figure>
                        </div>
                    </div>
                ))}
            </div>
            {/* <Modal size={"sm"} show={showPage} onHide={() => setShowPage(false)} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className='link-door d-flex flex-column justify-content-center align-items-center shop-now'>
                        <FaGift color="green" size={80} className='mb-3' />

                        <Link to={"https://playluckyduck.com/"}>
                            Shop Now
                        </Link>
                    </div>
                </Modal.Body>
            </Modal> */}
        </>
    );
};

export default Door;

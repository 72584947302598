import React, { useState } from 'react';
import { Wheel } from 'react-custom-roulette';

import Spin from "../../src/assets/images/Spin.png";
import CustomPointerImage from "../../src/assets/images/pointer.svg";


const SpinWheel = ({ mustSpin, setMustSpin, handleSpinClick, prizeNumber, data }) => {

    return (
        <>
            <div className="wheel-container">
                <div className="gradient-border">
                    <Wheel
                        mustStartSpinning={mustSpin}
                        prizeNumber={prizeNumber}
                        data={data}
                        spinDuration={[0.5]}
                        outerBorderWidth={[0]}
                        innerBorderColor={["#f2f2f2"]}
                        radiusLineColor={["transparent"]}
                        radiusLineWidth={[1]}
                        textColors={["#f5f5f5"]}
                        textDistance={80}
                        fontSize={[18]}
                        fontWeight={600}
                        backgroundColors={[
                            "#3f297e", "#175fa9", "#169ed8", "#239b63", "#64b031",
                            "#efe61f", "#f7a416", "#e6471d", "#dc0936", "#e5177b",
                            "#be1180", "#871f7f"
                        ]}
                        onStopSpinning={() => {
                            setMustSpin(false);
                        }}
                        pointerProps={{ src: CustomPointerImage }} // Custom pointer image
                    />
                    <button disabled className="button roulette-button">
                        <img src={Spin} alt="spin" />
                    </button>
                </div>
                <div className="dots-container">
                    {Array.from({ length: 8 }).map((_, i) => (
                        <div key={i} className={`dot dot-${i}`} />
                    ))}
                </div>
            </div>
        </>
    );
};


export default SpinWheel;
import React from "react";

function TimeLeftCard({ timeLeft }) {
  return (
    <div className="w-100 bg-white rounded-3 p-3 mt-2 text-center">
      <span className="fw-bold fs-6">Time</span>
      <p
        className={`fs-5 m-0  rounded-5 text-white p-2  mt-2 ${timeLeft === 0 ? "bg-danger" : "bg-black"
          }`}
      >
        Time Left : <span> {timeLeft}s</span>
      </p>
    </div>
  );
}

export default TimeLeftCard;

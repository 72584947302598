import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";

function GuessInput({ guessed, onGuessSubmit }) {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleGuessClick = () => {
    if (!guessed) {
      onGuessSubmit(inputValue);
      setInputValue("");
    }
  };

  return (
    <div className="w-100 bg-white rounded-3 p-1 ps-3 mt-2">
      <Row className="w-100">
        <Col md={8} className="border-bottom border-1 border-gray">
          <div className="d-flex flex-row justify-content-between gap-1 py-2">
            <span className="fs-5 fw-bold">$</span>
            <input
              type="number"
              name="guess_price"
              className="w-100  guess_price"
              value={inputValue}
              onChange={handleInputChange}
              disabled={guessed}
            />
          </div>
        </Col>
        <Col md={4} className="p-0">
          <button
            className={` rounded-3 fw-bold border-0 py-3 px-5 w-100 h-100 ${guessed ? "disable-btn" : "bg-warning"
              }`}
            onClick={handleGuessClick}
            disabled={guessed}
          >
            GUESS
          </button>
        </Col>
      </Row>
    </div>
  );
}

export default GuessInput;
